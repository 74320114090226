import axios from "axios";
import store from "@/store";
import router from "@/router";
import {Buffer} from "buffer";

class NoticiaLogic {
    static instance = null;
    static createInstance() {
        var object = new NoticiaLogic();
        return object;
    }

    static getInstance() {
        if (!NoticiaLogic.instance) {
            NoticiaLogic.instance = NoticiaLogic.createInstance();
        }
        return NoticiaLogic.instance;
    }

    constructor() {
    }

    async listar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/noticia/listar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[NoticiaLogic] listar()::ERROR >>> "+error);
        }
    }

    async crear(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var formData = new FormData();
            formData.append('fileImagen', objRequest.fileImagen);
            formData.append('idUsuario', objRequest.idUsuario);
            formData.append('titulo', objRequest.titulo);
            formData.append('descripcionCorta', objRequest.descripcionCorta);
            formData.append('descripcionLarga', objRequest.descripcionLarga);

            var urlRequest = ENV_APIPATH+"/noticia/crear";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[NoticiaoLogic] crear()::ERROR >>> "+error);
        }
    }

    async editar(objNoticia){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var formData = new FormData();
            formData.append('fileImagen', objNoticia.fileImagen);
            formData.append('id', objNoticia.id);
            formData.append('idUsuario', objNoticia.idUsuario);
            formData.append('titulo', objNoticia.titulo);
            formData.append('slug', objNoticia.slug);
            formData.append('descripcionCorta', objNoticia.descripcionCorta);
            formData.append('descripcionLarga', objNoticia.descripcionLarga);

            var urlRequest = ENV_APIPATH+"/noticia/editar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[NoticiaLogic] editar()::ERROR >>> "+error);
        }
    }

    async detalle(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/noticia/detalle";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[NoticiaLogic] detalle()::ERROR >>> "+error);
        }
    }

    async borrar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/noticia/borrar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[NoticiaLogic] borrar()::ERROR >>> "+error);
        }
    }
}

const instance = NoticiaLogic.getInstance();
export default instance;